import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { graphql, navigate } from 'gatsby';
import { H2, Button } from '@allthingswww/client-act-shared';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';

import { getEventByID } from '../redux/event';
import Image from '../components/Image';
import Content from '../components/ContentMdxs';
import Layout from '../components/Layout';
import titleIcon from '../images/headerIcon.png';
import { getMyInfo } from '../redux/member';
import { getAuthenticated } from '../redux/user';

const Row = styled.div`
display: flex;
justify-content: space-between;
margin: 2rem;

`;
const ItemWrapper = styled.div`
flex-grow: 1;
flex-basis: 100%;
display: flex;
`;

const Label = styled.p`
  font-weight: bold;
  margin: 0 0.5rem;
  min-width: max-content;
`;

const MainContainer = styled.div``;
const TitleContainer = styled.div`
  margin: 30px 0;
`;
const InfoContainer = styled.div`
  border-top: 1px solid #0f0f3a;
  border-bottom: 1px solid #0f0f3a;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px 0;
  & > div + div {
    margin-left: 30px;
  }
`;

const FeatureImageContainer = styled.div`
  flex: 0 0 50%;
  & > img {
    max-width: 100%;
    @media only screen and (max-width: 636px) {
      text-align: center;
    }
  }
`;
const MainInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const DateTimeContainer = styled.div`
  width: 100%;
  display: flex;
  font-size: 24px;
  font-weight: bold;
`;
const DateContainer = styled.div`
  flex-grow: 1;
  margin-top: 1rem;
`;

const RegistrationContainer = styled.div`
  margin-top: 30px;
  flex-grow: 1;
`;
const MemberFeeContainer = styled.div`
  display: flex;
  margin: 15px 0;
  align-items: center;
`;
const MemberFee = styled.div``;
const MemberFeeLabel = styled.div`
  flex-grow: 1;
  margin: 0 15px;
`;

const NonMemberFeeContainer = styled.div`
  display: flex;
  align-items: center;
`;
const NonMemberFee = styled.div``;
const NonMemberFeeLabel = styled.div`
  flex-grow: 1;
  margin: 0 15px;
`;

const RegisterButtonContainer = styled.div`
  margin: 15px 0;
  text-align: right;
  justify-content: space-around;
  display: flex;
  flex-direction: row;

`;

const RegistrationHeader = styled.div`
  font-size: 18px;
  margin-bottom: 15px 0;
`;
const MainBodyContainer = styled.div`
  border-bottom: 1px solid #0f0f3a;
`;
const Msg = styled.p`
  max-width: 450px;
  line-height: 22px;
`;

const Currency = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD',
});

const Value = styled.p`
  
`;

const Item = ({ label, value }) => (
  <ItemWrapper>
    <Label>
      {label}
      :
    </Label>
    <Value>{value}</Value>
  </ItemWrapper>
);

export const EventsPageTemplate = ({ data, location }) => {
  const { frontmatter, body } = data.page;
  const {
    title, subtitle, featuredImage, id,
  } = frontmatter;
  const eventDetails = useSelector(getEventByID(id));
  const { role } = useSelector(getMyInfo);
  const authenticated = useSelector(getAuthenticated);
  const [hideRegisterButton, setHideRegisterButton] = useState(
    !_.isEmpty(eventDetails) && (
      Date.parse(new Date().toLocaleDateString())
      > Date.parse(eventDetails[0].maxDateOfRegistration)
    ),
  );

  useEffect(() => {
    setHideRegisterButton(
      !_.isEmpty(eventDetails) && (
        Date.parse(new Date().toLocaleDateString())
        > Date.parse(eventDetails[0].maxDateOfRegistration)
      ),
    );
    if (eventDetails[0]) {
      if (!eventDetails[0].isPublic && !authenticated) {
        setHideRegisterButton(true);
      }
      if (eventDetails[0].remainingSeats === 0) {
        setHideRegisterButton(true);
      }
    }
  }, [eventDetails]);

  if (_.isEmpty(eventDetails)) {
    return (
      <Layout
        title={title}
        subtitle={subtitle}
        featuredImage={featuredImage}
        icon={titleIcon}
        meta={{ data, location }}
      >
        <div>Loading event...</div>
      </Layout>
    );
  }

  const {
    name = '',
    date = '',
    photoUrls = '',
    memberFee = 0,
    nonMemberFee = 0,
    locationName,
    maxDateOfRegistration,
    venuePhone,
    venueCity,
    venueEmail,
    venueZip,
    venueState,
    venueAddress2,
    venueAddress1,
    venueWebsite,
    venueName,
  } = eventDetails[0];

  const photos = photoUrls.split(',');

  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/register-attendees/${id}`);
  };

  const navigateToEventDetails = (e) => {
    e.preventDefault();
    navigate(`/event-details/${id}`);
  };

  return (
    <Layout
      title={title}
      subtitle={subtitle}
      headerBackGroundImage={featuredImage}
      condensed
      icon={titleIcon}
      meta={{ data, location }}
    >
      <MainContainer>
        <TitleContainer>
          <H2 margin="2em 0">{title}</H2>
        </TitleContainer>
        <InfoContainer>
          {photos[0] && (
            <FeatureImageContainer>
              <Image fileName={photos[0] === undefined ? '' : photos[0]} />
            </FeatureImageContainer>
          )}
          <MainInfoContainer>
            <DateTimeContainer>
              <DateContainer>{moment(date).format('MMMM Do YYYY, h:mm a')}</DateContainer>
            </DateTimeContainer>
            <RegistrationContainer>
              <RegistrationHeader>Registration Fee</RegistrationHeader>
              <MemberFeeContainer>
                <MemberFeeLabel>Member</MemberFeeLabel>
                <MemberFee>
                  {memberFee === 0 ? 'Free' : Currency.format(memberFee / 100)}
                  {' '}
                  {memberFee === 0 ? '' : '/ per person'}
                </MemberFee>
              </MemberFeeContainer>
              <NonMemberFeeContainer>
                <NonMemberFeeLabel>Non Member Fee</NonMemberFeeLabel>
                <NonMemberFee>
                  {nonMemberFee === 0
                    ? 'Free'
                    : Currency.format(nonMemberFee / 100)}
                  {' '}
                  {nonMemberFee === 0 ? '' : '/ per person'}
                </NonMemberFee>
              </NonMemberFeeContainer>
            </RegistrationContainer>

            <RegisterButtonContainer>
              {
              !hideRegisterButton && (
              <Button.Button type="primary" href="" handleClick={handleClick}>
                {role?.isMember || role?.isAdmin || role?.isSuperAdmin
                  ? 'Member Register'
                  : 'Non Member Register'}
              </Button.Button>
              )
                }
            </RegisterButtonContainer>

            {authenticated ? null : (
              !hideRegisterButton && (<Msg>*Login as a Member to receive the Member Price.</Msg>)
            )}
          </MainInfoContainer>
        </InfoContainer>
        <Row>
          {locationName && <Item label="location" value={locationName} />}
          {maxDateOfRegistration && <Item label="Deadline" value={maxDateOfRegistration} />}
          {venueName && <Item label="Venue name" value={venueName} />}
        </Row>
        <Row>
          {venueWebsite && <Item label="Venue website" value={venueWebsite} />}
          {venueAddress1 && <Item label="Venue Address 1" value={venueAddress1} />}
        </Row>
        <Row>
          {venueCity && <Item label="Venue City" value={venueCity} />}
          {venueState && <Item label="Venue State" value={venueState} />}
          {venueZip && <Item label="Venue Zip" value={venueZip} />}
        </Row>
        <Row>
          {venueEmail && <Item label="Venue Email" value={venueEmail} />}
          {venuePhone && <Item label="Venue Phone" value={venuePhone} />}
        </Row>
        <MainBodyContainer>
          <Content source={body} />
        </MainBodyContainer>
      </MainContainer>
    </Layout>
  );
};

export default EventsPageTemplate;

export const pageQuery = graphql` 
  query EventsPage($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        slug
        locationName
        address1
        address2
        city
        state
        zip
        title
        featuredImage
        subtitle
        memberFee
        nonMemberFee
        id
      }
    }
  }
`;
